<template>
  <router-view></router-view>
</template>

<script>
export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.startRouteGuard(to, from, next)
    })
  },

  beforeRouteUpdate(to, from, next) {
    next(vm => {
      vm.startRouteGuard(to, from, next)
    })
  },
  methods: {
    async startRouteGuard(to, from, next) {
      // TODO Check loggedin in local Store
      //await dispatchCheckLoggedIn(this.$store)
      const loggedIn = this.$store.state.users.isLoggedIn
      console.info(loggedIn)
      if (loggedIn) {
        if (to.path === "/login" || to.path === "/") {
          next("/dashboard")
        } else {
          next()
        }
      } else if (loggedIn === false) {
        if (to.path !== "register") next("/login")
      }
    },
  },
}
</script>
